import pngQr from "./assets/qr.png"
import TT from "./js/tt"
import { TW_AddLoadEvent } from "./js/twloader"
import { Toast, Dialog } from "./js/ttui"
import { DrawCircle } from "./js/geometryutil"
import { WriteText } from "./js/textutil"
import { DrawImage } from "./js/imageutil"

import pngSnake from "./assets/image1.png"
import pngBox from "./assets/image2.png"
import pngClear from "./assets/image3.png"
import pngPath from "./assets/image4.png"
import { CDotTableData, CDrawDotTable } from "./js/dottable"
import { RandomInt, GetRandQueueInRange } from "./js/math"

var A4Direction = "v";
const A4Width = 1240;
const A4Height = 1754;

TW_AddLoadEvent(Start);

//////////////////////
//程序入口
////////////////////
function Start() {
    //添加事件
    SetupBtnClick('btn1', () => { CreateA4(1); });
    SetupBtnClick('btn2', () => { CreateA4(2); });
    SetupBtnClick('btn3', () => { CreateA4(3); });
    SetupBtnClick('btn4', () => { CreateA4(4); });
    SetupBtnClick('btn8', () => { CreateA4(5); });
    SetupBtnClick('btn9', () => { CreateA4(6); });
    SetupBtnClick('btn10', () => { CreateA4(7); });
    SetupBtnClick('btn5', () => { GoToUrl(1); });
    SetupBtnClick('btn6', () => { GoToUrl(2); });
    SetupBtnClick('btn7', () => { GoToUrl(3); });
}

function SetupBtnClick(btnName, cb) {
    document.getElementById(btnName).addEventListener('click', cb);;
}

var m_mode = 1;
var toastDlg;
//跳转
function GoToUrl(category) {
    if (category == 1) {
        //数字迷阵
        location.href = "https://numbermaze.triweb.cn/index.html";
    } else if (category == 2) {
        //数织
        location.href = "https://nonogram.triweb.cn/index.html";
    } else if (category == 3) {
        //迷宫
        location.href = "https://mazea4.triweb.cn/index.html";
    }
}
//生成题目
function CreateA4(mode) {
    m_mode = mode || 1;
    toastDlg = new Toast({
        text: "生成中"
    });
    toastDlg.Show();

    if (m_mode == 1 || m_mode == 2) {
        InitCanvas('w');
        WriteText("纸笔游戏--贪吃蛇", 12.0, 2.0, 1.0);
        CreateDots(12, 10);
        DrawImage(pngSnake, [250, 50, 180, 180], 1);
        //说明
        let strDesc = "玩法说明\n";
        strDesc += "两人轮流画直线,每次向\n周围八个点中选一个画\n线,可以堵对方的路,不能\n交叉自己或别人的线,没\n路走就算输。";
        WriteText(strDesc, 1, 6, 0.5);
    } else if (m_mode == 3) {
        InitCanvas('w');
        WriteText("纸笔游戏--画方格", 12.0, 2.0, 1.0);
        WriteText("数量:A____    B____", 4, 5, 0.5);
        CreateDots(6, 6, 4, 6.5, 1.5);
        WriteText("数量:A____    B____", 18, 5, 0.5);
        CreateDots(6, 6, 18, 6.5, 1.5);
        DrawImage(pngBox, [250, 50, 180, 180], 1);
        let strDesc = "玩法说明\n";
        strDesc += "双方轮流在相邻的两点之间画垂直或者水平直线，不能是斜线和跨格。\n如果画成一个方格，就占领这个方格，可以用A或者B进行标记，";
        strDesc += "并获\n得再次画一条线的机会。最后统计占领的格子数量，数量多的为获胜者。";
        WriteText(strDesc, 6, 16, 0.5);
    } else if (m_mode == 4) {
        InitCanvas('w');
        WriteText("纸笔游戏--开心消消乐", 10.0, 2.0, 1.0);
        CreateDots(8, 8, 3, 6, 1.2, 0.4);
        CreateDots(8, 8, 17, 6, 1.2, 0.4);
        DrawImage(pngClear, [250, 50, 180, 180], 1);
        let strDesc = "玩法说明\n";
        strDesc += "两个玩家轮流划掉1到3个圆，即每次可以划掉1，2，3个圆，但只能画直线。\n当圆被全部划掉时，游戏结束。";
        strDesc += "划掉最后一个圆的人输掉。";
        WriteText(strDesc, 6, 17, 0.5);
    } else if (m_mode == 5) {
        InitCanvas('v');
        WriteText("一笔画", 8.0, 2.0, 1.0);
        let numCol = 6;
        let numRow = 4;
        CreateDots(numCol, numRow, 2.5, 6, 1.2, 0.4, "题目1");
        CreateDots(numCol, numRow, 12, 6, 1.2, 0.4, "题目2");
        CreateDots(numCol, numRow, 2.5, 14, 1.2, 0.4, "题目3");
        CreateDots(numCol, numRow, 12, 14, 1.2, 0.4, "题目4");
        CreateDots(numCol, numRow, 2.5, 22, 1.2, 0.4, "题目5");
        CreateDots(numCol, numRow, 12, 22, 1.2, 0.4, "题目6");
        DrawImage(pngPath, [180, 10, 150, 150], 1);
    } else if (m_mode == 6) {
        InitCanvas('v');
        WriteText("一笔画", 8.0, 2.0, 1.0);
        let numCol = 8;
        let numRow = 8;
        CreateDots(numCol, numRow, 2.5, 6, 1.0, 0.35, "题目1");
        CreateDots(numCol, numRow, 12, 6, 1.0, 0.35, "题目2");
        CreateDots(numCol, numRow, 2.5, 18, 1.0, 0.35, "题目3");
        CreateDots(numCol, numRow, 12, 18, 1.0, 0.35, "题目4");
        DrawImage(pngPath, [180, 10, 150, 150], 1);
    } else if (m_mode == 7) {
        InitCanvas('v');
        WriteText("一笔画", 8.0, 2.0, 1.0);
        let numCol = 14;
        let numRow = 10;
        CreateDots(numCol, numRow, 4.0, 6, 1.0, 0.35, "题目1");
        CreateDots(numCol, numRow, 4.0, 18, 1.0, 0.35, "题目2");
        DrawImage(pngPath, [180, 10, 150, 150], 1);
    }
    ShowQr([10, 10, 150, 150], 1);
}

//A4 画点
function CreateDots(col, row, startX, startY, dstep, dR, strTxt = "") {
    //2.draw box
    startX = startX || 8.5;
    startY = startY || 5;

    let tbData = new CDotTableData();
    tbData.colNum = col;
    tbData.rowNum = row;
    tbData.r = dR || 0.1;
    tbData.colStep = dstep || 1.5;
    tbData.rowStep = dstep || 1.5;

    let drawTB = new CDrawDotTable();
    drawTB.DrawTable(tbData, startX, startY, "Gray");
    if (m_mode == 2) {
        //获得路径
        let num = RandomInt(2, 4);
        for (let i = 0; i < num; i++) {
            let aPath = tbData.CreateOnePath(RandomInt(3, 5));
            //绘制路径
            drawTB.DrawOnePath(aPath, tbData);
        }
    } else if (m_mode == 4) {
        //隐藏部分点
        let n = RandomInt(8, 18);
        let arrPts = tbData.GetRandomArray(n);
        drawTB.DrawWhiteDots(arrPts, tbData);
    } else if (m_mode == 5 || m_mode == 6 || m_mode == 7) {
        do {
            let aPath = tbData.CreateFullPath();
            if (aPath.length > 0) {
                //绘制路径
                //drawTB.DrawOnePath(aPath, tbData);
                let arr2 = GetRandQueueInRange(m_mode - 4, 1, aPath.length - 2);
                let arrPts = arr2.map((idx2) => {
                    return aPath[idx2];
                })
                drawTB.FillGreyDots([aPath[0],aPath[aPath.length - 1]], tbData);
                drawTB.FillGreyDots(arrPts, tbData);
                break;
            }
        } while (1);
    }

    if (strTxt) {
        WriteText(strTxt, startX - 0.8, startY - 1.2, 0.5);
    }
}

function ShowQr(params, scale = 60) {
    setTimeout(() => {
        //二维码
        DrawImage(pngQr, params, scale, () => {
            toastDlg.Close();
            ShowImageDlg();
        });
    }, 10);

}

//旋转画图
function InitCanvas(direction = "v") {
    TT.canvas = document.getElementById("board");
    if (A4Direction != direction) {
        A4Direction = direction;
        if (A4Direction == "w") {
            [TT.canvas.width, TT.canvas.height] = [A4Height, A4Width];
        } else {
            [TT.canvas.width, TT.canvas.height] = [A4Width, A4Height];
        }
    }
    TT.ctx = TT.canvas.getContext("2d");
    TT.width = TT.canvas.width;
    TT.height = TT.canvas.height;

    TT.ctx.fillStyle = "white";
    TT.ctx.fillRect(0, 0, TT.width, TT.height);

}

//显示生成的题目图片，长按保存
function ShowImageDlg() {
    let dlgWidth = 350;
    let dlgHeight = 500;

    if (A4Direction == "w") {
        [dlgWidth, dlgHeight] = [350, 280];
    }

    let strImg = "<img ";
    strImg += "src=" + TT.canvas.toDataURL('png', 1.0);
    strImg += " style='width:" + dlgWidth + "px;height:" + dlgHeight + "px;'></img>";
    let dlg1 = new Dialog({
        title: "长按图片，保存下载",
        text: strImg
    });

    dlg1.Show();
}

