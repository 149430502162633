class TT {
    constructor() {
        this.name = 'I am game environment';
        this.ctx=null;
        this.canvas =null;
        this.imgs = {};
    }
}
 
const instance = new TT();
 
export default instance;