import { DrawImage } from "./imageutil";
import { WriteText } from "./textutil";
import { DrawCircle, DrawLine } from "./geometryutil";
import { RandomInt, GetRandQueue, GetRandQueueInRange } from "./math"

//井字形网格
class CDotTableData {
    posX = 0;
    posY = 0;
    rowNum = 6;
    colNum = 6;
    rowStep = 0.8;
    colStep = 0.8;
    /** @type {number} 半径 */
    r = 0.8;
    //点集
    pts = [];

    constructor() {

    }

    /**
     * 获得
     */
    GetGridPoints(staX, staY) {
        this.posX = staX;
        this.posY = staY;
        this.pts = [];
        for (let i = 0; i < this.rowNum; i++) {
            //按行计算表格中的点坐标
            for (let j = 0; j < this.colNum; j++) {
                let x0 = this.posX + j * this.colStep;
                let y0 = this.posY + i * this.rowStep;
                this.pts.push([x0, y0]);
            }
        }
        return this.pts;
    }

    /**
     * 根据位置范围坐标
     * @param {number} c 
     * @param {number} r 
     */
    GetPtByCoord(c, r) {
        return this.pts[this.GetPositionByColRow(c, r)];
    }

    GetPositionByColRow(c, r) {
        return r * this.colNum + c;
    }

    GetColRowByPosition(pos) {
        return [pos % this.colNum, parseInt(pos / this.colNum)];
    }

    GetPtSize() {
        return this.colNum * this.rowNum;
    }

    /**
     * 创建一条连续的路径
     * @param {number} n 
     */
    CreateOnePath(n) {
        let arrPts = [];
        //起始位置
        let ipos0 = RandomInt(0, this.rowNum * this.colNum - 1);
        arrPts.push(ipos0);
        let max = 0;
        for (let i = 0; i < n; i++) {
            ++max;
            //获得可能下个位置
            let nextPts = this.GetNextPositon(arrPts[arrPts.length - 1]);
            if (nextPts.length == 0) break;
            nextPts = nextPts.filter((item) => {
                return arrPts.includes(item) == false;
            })
            if (nextPts.length == 0) break;
            //随机选取
            nextPts = GetRandQueue(nextPts, nextPts.length);
            for (let j = 0; j < nextPts.length; j++) {
                let ipos2 = nextPts[j];
                if (arrPts.length <= 2) {
                    arrPts.push(ipos2);
                    break;
                }
                //判断是不是同向
                if (this.CheckAtSameDirection(arrPts[arrPts.length - 2], arrPts[arrPts.length - 1], ipos2)) {
                    arrPts.push(ipos2);
                    break;
                }
            }
        }

        return arrPts;
    }

    GetNextPositon(ipos0, isCross = true) {
        let posArr = [];
        let col0, row0;
        [col0, row0] = this.GetColRowByPosition(ipos0);
        let col1 = col0 - 1;
        let row1 = row0 - 1;
        if (isCross && col1 >= 0 && col1 < this.colNum && row1 >= 0 && row1 < this.rowNum) {
            posArr.push(this.GetPositionByColRow(col1, row1));
        }
        col1 = col0;
        row1 = row0 - 1;
        if (col1 >= 0 && col1 < this.colNum && row1 >= 0 && row1 < this.rowNum) {
            posArr.push(this.GetPositionByColRow(col1, row1));
        }
        col1 = col0 + 1;
        row1 = row0 - 1;
        if (isCross && col1 >= 0 && col1 < this.colNum && row1 >= 0 && row1 < this.rowNum) {
            posArr.push(this.GetPositionByColRow(col1, row1));
        }

        col1 = col0 - 1;
        row1 = row0;
        if (col1 >= 0 && col1 < this.colNum && row1 >= 0 && row1 < this.rowNum) {
            posArr.push(this.GetPositionByColRow(col1, row1));
        }

        col1 = col0 + 1;
        row1 = row0;
        if (col1 >= 0 && col1 < this.colNum && row1 >= 0 && row1 < this.rowNum) {
            posArr.push(this.GetPositionByColRow(col1, row1));
        }

        col1 = col0 - 1;
        row1 = row0 + 1;
        if (isCross && col1 >= 0 && col1 < this.colNum && row1 >= 0 && row1 < this.rowNum) {
            posArr.push(this.GetPositionByColRow(col1, row1));
        }

        col1 = col0;
        row1 = row0 + 1;
        if (col1 >= 0 && col1 < this.colNum && row1 >= 0 && row1 < this.rowNum) {
            posArr.push(this.GetPositionByColRow(col1, row1));
        }

        col1 = col0 + 1;
        row1 = row0 + 1;
        if (isCross && col1 >= 0 && col1 < this.colNum && row1 >= 0 && row1 < this.rowNum) {
            posArr.push(this.GetPositionByColRow(col1, row1));
        }

        return posArr;
    }

    CheckAtSameDirection(pos0, pos1, pos2) {
        let coord0 = this.GetColRowByPosition(pos0);
        let coord1 = this.GetColRowByPosition(pos1);
        let coord2 = this.GetColRowByPosition(pos2);

        let vec1 = [coord0[0] - coord1[0], coord0[1] - coord1[0]];
        let vec2 = [coord2[0] - coord1[0], coord2[1] - coord1[0]];

        let d1 = vec1[0] * vec2[0] + vec1[1] * vec2[1];
        let d2 = Math.sqrt(vec1[0] * vec1[0] + vec1[1] * vec1[1]);
        let d3 = Math.sqrt(vec2[0] * vec2[0] + vec2[1] * vec2[1]);
        if (Math.acos(d1 / (d2 * d3)) >= Math.PI * 0.5) {
            return true;
        }

        return false;
    }

    GetRandomArray(n) {
        return GetRandQueueInRange(n, 0, this.GetPtSize() - 1);
    }

    /**
     * 创建一条经过所有点的路径
     */
    CreateFullPath() {
        let arrRecords = [];
        let arrPts = [];
        //初始位置
        let ipos0 = RandomInt(0, this.GetPtSize() - 1);
        arrPts.push(ipos0);
        let max = 0;
        do {
            ++max;
            if(arrPts.length == 0) break;
            let nextPts = this.GetNextPositon(arrPts[arrPts.length - 1], false);
            if (nextPts.length == 0) break;
            nextPts = nextPts.filter((item) => {
                //过滤经过的点
                return arrPts.includes(item) == false;
            })
            if (nextPts.length > 0) {
                arrRecords.push(nextPts);
                arrPts.push(nextPts[0]);
            } else {
                //如果没有路可以走了
                for (let j = 0; j < arrRecords.length; j++) {
                    let prevArrRec = arrRecords[arrRecords.length - 1];
                    if (prevArrRec.length == 1) {
                        arrRecords.pop();
                        arrPts.pop();
                    }
                    if(arrRecords.length == 0) break;
                    arrRecords[arrRecords.length - 1].pop();
                    if (arrRecords[arrRecords.length - 1].length > 0) {
                        arrPts[arrPts.length - 1] = arrRecords[arrRecords.length - 1][0];
                        break;
                    }
                }
            }
        } while (arrPts.length < this.GetPtSize() && max < 1000)

        if(arrPts.length != this.GetPtSize()){
            return [];
        }

        return arrPts;
    }
}

//绘制网格线
class CDrawDotTable {
    /** @type {CanvasRenderingContext2D} */
    context2D = null;
    lineWidth = 0.2;
    circleWidth = 0.05;

    constructor(ctx) {
        this.context2D = ctx;
    }

    /**
     * 绘制表格
     * @param {CDotTableData} tbData
     * @param {number} x0
     * @param {number} y0
     * @param {*} strColor 
     * @param {*} strStyle 
     */
    DrawTable(tbData, x0, y0, strColor = 'black', strStyle = 'solid') {
        let pts = tbData.GetGridPoints(x0, y0);
        for (let i = 0, len = pts.length; i < len; i++) {
            //绘制
            DrawCircle(pts[i][0], pts[i][1], tbData.r, this.circleWidth, 0, strColor);
        }
    }

    /**
     * 绘制路径
     * @param {*} pathArr 
     * @param {*} tbData 
     */
    DrawOnePath(pathArr, tbData) {
        for (let i = 1; i < pathArr.length; i++) {
            let pos1 = pathArr[i - 1];
            let pos2 = pathArr[i];

            let pt1 = tbData.pts[pos1];
            let pt2 = tbData.pts[pos2];

            //绘制直线
            DrawLine(pt1[0], pt1[1], pt2[0], pt2[1], this.lineWidth, 0);
        }
    }

    DrawWhiteDots(posArr, tbData) {
        for (let i = 0; i < posArr.length; i++) {
            let pos1 = posArr[i];
            let pt1 = tbData.pts[pos1];
            DrawCircle(pt1[0], pt1[1], tbData.r, this.circleWidth + 0.02, 0, "White");
        }
    }

    FillGreyDots(posArr, tbData, dotR){
        for (let i = 0; i < posArr.length; i++) {
            let pos1 = posArr[i];
            let pt1 = tbData.pts[pos1];
            DrawCircle(pt1[0], pt1[1], dotR||tbData.r, this.circleWidth, 0, "Black", "Grey");
        }
    }

}

export {
    CDotTableData,
    CDrawDotTable
}
